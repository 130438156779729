'use strict'
const name = 'BgFake3D'
const properties = {
    hideOnStart: false,
    requestFullScreenHeight: true,
    getMaxTravel(elementMeasure, viewportHeight) {
        return viewportHeight + elementMeasure.height
    },
    groups: ['animation', 'background'],
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        },
        speedFactor: {
            type: 'number',
            default: 0.9
        },
        perspectiveParent: {
            type: 'element'
        },
        parallaxParent: {
            type: 'element'
        }
    }
}

function register({factory}) {
    /**
     * @param {HTMLElement|HTMLElement[]} elements DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters.
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, {
        perspectiveParent,
        parallaxParent,
        speedFactor = properties.schema.speedFactor.default, // TODO: when bgScrub is removed, we can remove this, factor becomes a constant 1
        ...params
    } = {}) {
        const sequence = factory.sequence(params)
        sequence.add([
            factory.animate('BaseBgParallaxY', elements, duration, delay, {
                parallaxParent,
                speedFactor,
                start: 0,
                end: 1,
                ease: 'none'
            }),
            factory.animate('BaseBgZoom', elements, duration, delay, {
                perspectiveParent,
                speedFactor,
                in: {
                    start: 0,
                    end: 1,
                    scale: 0.679,
                    ease: 'none'
                }
            }),
            factory.animate('BaseBgScale', elements, duration, delay, {
                in: {
                    start: 0,
                    end: 1,
                    scaleY: 1.3,
                    ease: 'none'
                }
            })
        ])

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
